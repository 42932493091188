import {
  json,
  type MetaFunction,
  type ActionFunctionArgs,
  redirect,
} from '@remix-run/node'
import { Form, Link, useActionData, useSearchParams } from '@remix-run/react'
import { GeneralErrorBoundary } from '~/components/error-boundary.tsx'
import { Spacer } from '~/components/ui/spacer.tsx'
import {
  authenticator,
  login,
  requireAnonymous,
} from '~/features/auth/auth.server.ts'
import { commitSession, getSession } from '~/utils/config/session.server.ts'
import { AuthLayout } from '~/components/layout/auth-layout.tsx'
import { conform, useForm } from '@conform-to/react'
import { getFieldsetConstraint, parse } from '@conform-to/zod'
import { emailSchema, passwordSchema } from '~/utils/user-validation.ts'
import { z } from 'zod'
import { checkboxSchema } from '~/utils/zod-extensions.ts'
import { combineResponseInits, useIsPending } from '~/utils/misc.tsx'
import { validateCSRF } from '~/utils/csrf.server.ts'
import { checkHoneypot } from '~/utils/honeypot.server.ts'
import { safeRedirect } from 'remix-utils/safe-redirect'
import { AuthenticityTokenInput } from 'remix-utils/csrf/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import {
  CheckboxField,
  ErrorList,
  Field,
} from '~/components/ui/inputs/forms.tsx'
import { Label } from '~/components/ui/inputs/label.tsx'
import { StatusButton } from '~/components/ui/inputs/button/status-button.tsx'
import { prisma } from '~/utils/config/db.server.ts'
import { useHints } from '~/utils/client-hints.tsx'
import { DiscountStatusEnum, RoleEnum } from 'types/enums.ts'

export const meta: MetaFunction = () => {
  return [{ title: 'Login to Miistico' }]
}

export async function updateInvites(userId: number) {
  await prisma.invite.update({
    where: {
      recipientId: userId,
      discountStatus: {
        not: DiscountStatusEnum.EXPIRED,
      },
    },
    data: {
      discountStatus: DiscountStatusEnum.EXPIRED,
    },
  })
}

export async function action({ request }: ActionFunctionArgs) {
  await requireAnonymous(request)
  const formData = await request.formData()
  await validateCSRF(formData, request.headers)
  checkHoneypot(formData)
  const submission = await parse(formData, {
    schema: intent =>
      LoginFormSchema.transform(async (data, ctx) => {
        if (intent !== 'submit') return { ...data, session: null }

        const session = await login(data)
        if (!session) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Email o contraseña incorrecto',
          })
          return z.NEVER
        }

        return { ...data, session }
      }),
    async: true,
  })
  // get the password off the payload that's sent back
  delete submission.payload.password

  if (submission.intent !== 'submit') {
    // @ts-expect-error - conform should probably have support for doing this
    delete submission.value?.password
    return json({ status: 'idle', submission } as const)
  }
  if (!submission.value?.session) {
    return json({ status: 'error', submission } as const, { status: 400 })
  }

  const { session, remember, redirectTo, timezone } = submission.value

  return handleNewSession({
    request,
    session,
    remember: remember ?? false,
    redirectTo,
    timezone,
  })
}

async function updateUserDetails(userId: number, timezone: string) {
  const user = await prisma.user.update({
    where: { id: userId },
    data: {
      timezone,
    },
    select: {
      receivedInvite: true,
      role: true,
    },
  })

  if (
    user.role === RoleEnum.USER &&
    user.receivedInvite?.discountStatus !== DiscountStatusEnum.EXPIRED &&
    user.receivedInvite?.expiresOn &&
    user.receivedInvite.expiresOn < new Date()
  ) {
    await updateInvites(userId)
  }
}

export async function handleNewSession(
  {
    request,
    session,
    redirectTo,
    remember,
    timezone,
  }: {
    request: Request
    session: { userId: number; id: number; expirationDate: Date }
    remember: boolean
    redirectTo?: string
    timezone: string
  },
  responseInit?: ResponseInit,
) {
  await updateUserDetails(session.userId, timezone)
  const authSession = await getSession(request.headers.get('cookie'))
  const keyToSet = authenticator.sessionKey
  authSession.set(keyToSet, session.id)

  return redirect(
    safeRedirect(redirectTo),
    combineResponseInits(
      {
        headers: {
          'Set-Cookie': await commitSession(authSession, {
            // Cookies with no expiration are cleared when the tab/window closes
            expires: remember ? session.expirationDate : undefined,
          }),
        },
      },
      responseInit,
    ),
  )
}

const LoginFormSchema = z.object({
  email: emailSchema,
  password: passwordSchema,
  redirectTo: z.string().optional(),
  remember: checkboxSchema(),
  timezone: z.string(),
})

export default function LoginPage() {
  const actionData = useActionData<typeof action>()
  const isPending = useIsPending()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')
  const timezone = useHints().timeZone

  const [form, fields] = useForm({
    id: 'login-form',
    constraint: getFieldsetConstraint(LoginFormSchema),
    defaultValue: { redirectTo },
    lastSubmission: actionData?.submission,
    onValidate({ formData }) {
      return parse(formData, { schema: LoginFormSchema })
    },
    shouldRevalidate: 'onBlur',
  })

  return (
    <AuthLayout>
      <div className="flex items-center justify-center gap-2">
        <span className="text-h4">¿Eres nuevo?</span>
        <Link to={`/signup?${searchParams}`} className="underline">
          Crea una cuenta
        </Link>
      </div>
      <Spacer size="4xs" />
      <div>
        <div className="mx-auto w-full max-w-md ">
          <Form method="POST" name="login" {...form.props}>
            <AuthenticityTokenInput />
            <HoneypotInputs />
            <Field
              labelProps={{ children: 'Email' }}
              inputProps={{
                ...conform.input(fields.email),
                autoComplete: 'email',
                autoFocus: true,
                placeholder: 'Email',
              }}
              errors={fields.email.errors}
            />
            <Spacer size="4xs" />
            <Field
              labelProps={{
                asChild: true,
                children: (
                  <div className="flex items-center justify-between">
                    <Label htmlFor={fields.password.id}>Contraseña</Label>
                    <div className="mb-2">
                      <Link
                        to={`/forgot-password`}
                        className="text-body-xs font-semibold"
                      >
                        ¿Olvidaste la contraseña?
                      </Link>
                    </div>
                  </div>
                ),
              }}
              inputProps={{
                ...conform.input(fields.password, {
                  type: 'password',
                }),
                placeholder: 'Contraseña',
                autoComplete: 'current-password',
              }}
              errors={fields.password.errors}
            />
            <Spacer size="4xs" />
            <div className="flex justify-between gap-4">
              <CheckboxField
                labelProps={{
                  htmlFor: fields.remember.id,
                  children: 'Mantén la sesión iniciada',
                }}
                checkboxProps={conform.input(fields.remember, {
                  type: 'checkbox',
                })}
                errors={fields.remember.errors}
              />
            </div>

            <input {...conform.input(fields.redirectTo)} type="hidden" />
            <input
              {...conform.input(fields.timezone)}
              type="hidden"
              value={timezone}
            />
            <ErrorList errors={form.errors} id={form.errorId} />

            <div className="flex items-center justify-between gap-6 pt-3">
              <StatusButton
                className="w-full font-bold"
                status={isPending ? 'pending' : actionData?.status ?? 'idle'}
                type="submit"
                disabled={isPending}
              >
                Iniciar sesión
              </StatusButton>
            </div>
          </Form>
        </div>
      </div>
    </AuthLayout>
  )
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />
}
